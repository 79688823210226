export const cards = {
    EMPTY: 0,
    BACKSIDE: 1,
    JOKER_START: 2,
    JOKER_END: 3,
    JOKER_1: 4,
    HEARTS_2: 102,
    HEARTS_3: 103,
    HEARTS_4: 104,
    HEARTS_5: 105,
    HEARTS_6: 106,
    HEARTS_7: 107,
    HEARTS_8: 108,
    HEARTS_9: 109,
    HEARTS_10: 110,
    HEARTS_JACK: 111,
    HEARTS_QUEEN: 112,
    HEARTS_KING: 113,
    HEARTS_ACE: 114,

    DIAMONDS_2: 202,
    DIAMONDS_3: 203,
    DIAMONDS_4: 204,
    DIAMONDS_5: 205,
    DIAMONDS_6: 206,
    DIAMONDS_7: 207,
    DIAMONDS_8: 208,
    DIAMONDS_9: 209,
    DIAMONDS_10: 210,
    DIAMONDS_JACK: 211,
    DIAMONDS_QUEEN: 212,
    DIAMONDS_KING: 213,
    DIAMONDS_ACE: 214,

    CLUBS_2: 302,
    CLUBS_3: 303,
    CLUBS_4: 304,
    CLUBS_5: 305,
    CLUBS_6: 306,
    CLUBS_7: 307,
    CLUBS_8: 308,
    CLUBS_9: 309,
    CLUBS_10: 310,
    CLUBS_JACK: 311,
    CLUBS_QUEEN: 312,
    CLUBS_KING: 313,
    CLUBS_ACE: 314,

    SPADES_2: 402,
    SPADES_3: 403,
    SPADES_4: 404,
    SPADES_5: 405,
    SPADES_6: 406,
    SPADES_7: 407,
    SPADES_8: 408,
    SPADES_9: 409,
    SPADES_10: 410,
    SPADES_JACK: 411,
    SPADES_QUEEN: 412,
    SPADES_KING: 413,
    SPADES_ACE: 414,
};

const cardFiles = {
    0: 'empty.png',
    1: 'Rücken.png',
    2: 'Joker-Einstiegsfrage.png',
    3: 'Joker-Abschlussfrage.png',
    4: 'Joker-Und_woher_weisst_Du_das.png',
    102: 'Herz-2.png',
    103: 'Herz-3.png',
    104: 'Herz-4.png',
    105: 'Herz-5.png',
    106: 'Herz-6.png',
    107: 'Herz-7.png',
    108: 'Herz-8.png',
    109: 'Herz-9.png',
    110: 'Herz-10.png',
    111: 'Herz-Bube.png',
    112: 'Herz-Dame.png',
    113: 'Herz-König.png',
    114: 'Herz-Ass.png',

    202: 'Karo-2.png',
    203: 'Karo-3.png',
    204: 'Karo-4.png',
    205: 'Karo-5.png',
    206: 'Karo-6.png',
    207: 'Karo-7.png',
    208: 'Karo-8.png',
    209: 'Karo-9.png',
    210: 'Karo-10.png',
    211: 'Karo-Bube.png',
    212: 'Karo-Dame.png',
    213: 'Karo-König.png',
    214: 'Karo-Ass.png',

    302: 'Kreuz-2.png',
    303: 'Kreuz-3.png',
    304: 'Kreuz-4.png',
    305: 'Kreuz-5.png',
    306: 'Kreuz-6.png',
    307: 'Kreuz-7.png',
    308: 'Kreuz-8.png',
    309: 'Kreuz-9.png',
    310: 'Kreuz-10.png',
    311: 'Kreuz-Bube.png',
    312: 'Kreuz-Dame.png',
    313: 'Kreuz-König.png',
    314: 'Kreuz-Ass.png',

    402: 'Pik-2.png',
    403: 'Pik-3.png',
    404: 'Pik-4.png',
    405: 'Pik-5.png',
    406: 'Pik-6.png',
    407: 'Pik-7.png',
    408: 'Pik-8.png',
    409: 'Pik-9.png',
    410: 'Pik-10.png',
    411: 'Pik-Bube.png',
    412: 'Pik-Dame.png',
    413: 'Pik-König.png',
    414: 'Pik-Ass.png',
};

const talonCards = [
    cards.HEARTS_2, cards.HEARTS_3, cards.HEARTS_4, cards.HEARTS_6, cards.HEARTS_7, cards.HEARTS_8, cards.HEARTS_9, cards.HEARTS_10, cards.HEARTS_QUEEN, cards.HEARTS_KING,
    cards.DIAMONDS_4, cards.DIAMONDS_5, cards.DIAMONDS_6, cards.DIAMONDS_7, cards.DIAMONDS_8, cards.DIAMONDS_9, cards.DIAMONDS_QUEEN, cards.DIAMONDS_KING, cards.DIAMONDS_ACE,
    cards.CLUBS_3, cards.CLUBS_5, cards.CLUBS_6, cards.CLUBS_7, cards.CLUBS_9, cards.CLUBS_10, cards.CLUBS_JACK, cards.CLUBS_QUEEN, cards.CLUBS_ACE,
    cards.SPADES_3, cards.SPADES_6, cards.SPADES_7, cards.SPADES_8, cards.SPADES_10, cards.SPADES_JACK, cards.SPADES_KING,

];

const specialGroup1 = [
    cards.HEARTS_ACE,
    cards.DIAMONDS_2, cards.DIAMONDS_JACK,
    cards.CLUBS_2, cards.CLUBS_8, cards.CLUBS_8,
    cards.SPADES_5, cards.SPADES_QUEEN
];

const specialGroup2 = [
    cards.HEARTS_5, cards.HEARTS_JACK,
    cards.DIAMONDS_3, cards.DIAMONDS_10,
    cards.CLUBS_4,
    cards.SPADES_2, cards.SPADES_4, cards.SPADES_9, cards.SPADES_ACE
];

/**
 * These cards are always visible when they are on a draw stack, even if it is a blind stack.
 * @type {number[]}
 */
export const alwaysRevealedCards = [
    cards.JOKER_START, cards.JOKER_END,
    ...specialGroup1,
    ...specialGroup2
]

function shuffleDeck(inputDeck) {
    for (let i = inputDeck.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [inputDeck[i], inputDeck[j]] = [inputDeck[j], inputDeck[i]];
    }
    return inputDeck;
}

export function imageAssetUrl(card) {
    /* eslint no-prototype-builtins: "off" */
    if (cardFiles.hasOwnProperty(card)) {
        return require('./assets/cards/' + cardFiles[card]);
    }
    return require('./assets/cards/' + cardFiles[cards.BACKSIDE]);
}

export function getShuffledTalon() {
    let talon = shuffleDeck(talonCards);
    talon.unshift(cards.JOKER_START);
    talon.push(cards.JOKER_END);
    return talon;
}

export function getShuffledGroup1() {
    return shuffleDeck(specialGroup1);
}

export function getShuffledGroup2() {
    return shuffleDeck(specialGroup2);
}
