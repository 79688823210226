<template>
  <div id="app">

    <div class="dropZones">
      <DropTarget :target-id="1" :background-card-id="cards.HEARTS_KING"/>
      <DropTarget :target-id="2" :background-card-id="cards.SPADES_JACK"/>
      <DropTarget :target-id="3" :background-card-id="cards.HEARTS_9"/>
      <DropTarget :target-id="4" :background-card-id="cards.SPADES_10"/>
      <DropTarget :target-id="5" :background-card-id="cards.HEARTS_QUEEN"/>
    </div>
    <div class="cardStacks">
      <DrawStack :initial-card-stack="getShuffledTalon()" :is-blind-stack="false" stack-id="talon"/>
      <DrawStack :initial-card-stack="getShuffledGroup1()" :is-blind-stack="false" stack-id="sg1"/>
      <DrawStack :initial-card-stack="getShuffledGroup2()" :is-blind-stack="false" stack-id="sg2"/>
      <DiscardPile :target-id="-1"/>
    </div>
  </div>
</template>

<script>
import {cards, getShuffledGroup1, getShuffledGroup2, getShuffledTalon} from "@/cardAssets";
import DropTarget from "@/components/DropTarget";
import DrawStack from "@/components/DrawStack";
import DiscardPile from "@/components/DiscardPile";

export default {
  name: 'App',
  components: {DiscardPile, DrawStack, DropTarget},
  data() {
    return {
      cards: cards,
    };
  },
  methods: {
    getShuffledTalon, getShuffledGroup1, getShuffledGroup2,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.dropZones {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
}

.cardStacks {
  display: flex;
  justify-content: space-around;
  padding-left: 50px;
  padding-right: 50px;
}

.container h4 {
  text-align: center;
  font-family: sans-serif;
}
</style>
