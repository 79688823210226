<template>
  <div class="dropTarget"
       @drop.prevent="dropHandler"
       @dragover.prevent
       @dragenter="dragEnterHandler"
       @dragexit="dragExitHandler"
  >
    <transition name="fade">
      <img class="card background" :src="imageUrl(this.emptyCard)" alt="card" draggable="false"
           v-if="this.topCardId === null"/>
      <img class="card" :src="imageUrl(this.topCardId)" alt="card" draggable="false" v-else/>
    </transition>
  </div>
</template>

<script>
import {cards, imageAssetUrl} from "@/cardAssets";
import {EventBus, EventNames} from "@/eventBus/eventBus";

export default {
  name: "DiscardPile",
  props: {
    targetId: {
      type: Number,
      required: true,
      validator: (value) => {
        return value < 0;
      }
    }
  },
  data() {
    return {
      emptyCard: cards.EMPTY,
      topCardId: null,
    }
  },
  methods: {
    imageUrl(cardId) {
      return imageAssetUrl(cardId);
    },
    dropHandler(ev) {
      // always show the backside of the cards
      this.topCardId = cards.BACKSIDE;
      // learn where the card came from
      let origin = ev.dataTransfer.getData('origin');
      console.log('Dropped card ' + this.topCardId + ' on slot ' + this.targetId + ' coming from ' + origin);
      // tell the originating stack that the drop was accepted
      EventBus.$emit(EventNames.legalDropFrom.concat(origin));
      ev.currentTarget.style.background = 'none';
    },
    dragEnterHandler(ev) {
      ev.currentTarget.style.background = 'yellow';
    },
    dragExitHandler(ev) {
      ev.currentTarget.style.background = 'none';
    },
  },
  created() {
    EventBus.$on(EventNames.legalDropFrom.concat(this.targetId.toString()), () => {
      this.topCardId = null;
    })
  }
}
</script>

<style scoped>
.dropTarget {
  width: 250px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.card {
  width: 100%;
}

.background {
  opacity: 0.9;
}
</style>
