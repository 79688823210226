<template>
  <div class="cardStack">
    <img :src="imageUrl()" :draggable="this.isDraggable" @dragstart="dragStart"/>
  </div>
</template>

<script>
import {alwaysRevealedCards, cards, imageAssetUrl} from "@/cardAssets";
import {EventBus, EventNames} from "@/eventBus/eventBus";

export default {
  name: "DrawStack",
  props: {
    stackId: {
      type: String,
      required: true,
    },
    isBlindStack: {
      type: Boolean,
      default: true
    },
    initialCardStack: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      talon: this.initialCardStack, // this already severs the connection to the prop
    }
  },
  created() {
    EventBus.$on(EventNames.legalDropFrom.concat(this.stackId), this.removeTopCard);
  },
  computed: {
    isEmpty() {
      return this.talon.length === 0;
    },
    nextCardId() {
      if (this.isEmpty) {
        return cards.EMPTY;
      } else {
        return this.talon[0];
      }
    },
    isDraggable() {
      return !this.isEmpty;
    }
  },
  methods: {
    imageUrl() {
      // not a blind stack OR it is a card which should we should always reveal
      if (!this.isBlindStack || (alwaysRevealedCards.indexOf(this.nextCardId) !== -1)) {
        return imageAssetUrl(this.nextCardId);
      } else {
        return imageAssetUrl(cards.BACKSIDE);
      }
    },
    dragStart(ev) {
      ev.dataTransfer.setData('cardId', this.nextCardId);
      ev.dataTransfer.setData('origin', this.stackId);
      console.log('Dragging from ' + this.stackId + ', got card ' + this.nextCardId);
    },
    removeTopCard() {
      this.talon.shift();
    }
  }
}
</script>

<style scoped>
.cardStack {
  width: 250px;
  padding: 10px;
}

img {
  width: 100%;
}
</style>
