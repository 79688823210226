<template>
  <div class="dropTarget"
       @drop.prevent="dropHandler"
       @dragover.prevent
       @dragenter="dragEnterHandler"
       @dragexit="dragExitHandler"
       @dragstart="dragStartHandler"
  >
    <transition name="fade">
      <img class="card background" :src="imageUrl(this.backgroundCardId)" alt="card" draggable="false"
           v-if="this.topCardId === null"/>
      <img class="card" :src="imageUrl(this.topCardId)" alt="card" draggable="true" v-else/>
    </transition>
  </div>
</template>

<script>
import {imageAssetUrl} from "@/cardAssets";
import {EventBus, EventNames} from "@/eventBus/eventBus";

export default {
  name: "DropTarget",
  props: {
    targetId: {
      type: Number,
      required: true,
    },
    backgroundCardId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      topCardId: null
    }
  },
  methods: {
    imageUrl(cardId) {
      return imageAssetUrl(cardId);
    },
    dragStartHandler(ev) {
      ev.dataTransfer.setData('cardId', this.topCardId);
      ev.dataTransfer.setData('origin', this.targetId.toString());
      // this.topCardId = null;
    },
    dropHandler(ev) {
      if (this.topCardId !== null) {
        // already occupied slot, do not accept the card
        return;
      }
      // show the card on the slot
      this.topCardId = ev.dataTransfer.getData('cardId');
      // learn where the card came from
      let origin = ev.dataTransfer.getData('origin');
      console.log('Dropped card ' + this.topCardId + ' on slot ' + this.targetId + ' coming from ' + origin);
      // tell the originating stack that the drop was accepted
      EventBus.$emit(EventNames.legalDropFrom.concat(origin));
      ev.currentTarget.style.background = 'none';
    },
    dragEnterHandler(ev) {
      if (this.topCardId === null) {
        ev.currentTarget.style.background = 'yellow';
      }
    },
    dragExitHandler(ev) {
      ev.currentTarget.style.background = 'none';
    },
  },
  created() {
    EventBus.$on(EventNames.legalDropFrom.concat(this.targetId.toString()), () => {
      this.topCardId = null;
    })
  }
}
</script>

<style scoped>
.dropTarget {
  width: 250px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.card {
  width: 100%;
}

.background {
  opacity: 0.6;
}
</style>
